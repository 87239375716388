<template>
  <div class="monitScreen_container">
    <div class="header">
      <div class="headerLeft" @click="backIndex">
        进入后台
      </div>
      <div class="headerCenter">视频监控系统</div>
      <div class="baseBox">
        <el-select v-model="base1" class="base-select" @change="baseChange">
          <el-option v-for="item in baseList" :key="item.id" :label="item.baseName" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="baseBox" style="left: 21vw">
        <el-select v-model="device" class="base-select" @change="deviceChange">
          <el-option v-for="item in deviceNameList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
    </div>
    <div class="main">
      <div class="mainVideo" style="align-items: flex-start;" :class="{ 'center': videoList.length<5 }">
        <div class="videoList" v-for="(item, index) in videoList" :key="index">
          <div class="videoWrap" style="position: relative;">
            <video :id="'video' + item" class="video-js vjs-default-skin vjs-big-play-centered videoStyle"
                   style="object-fit: fill;" autoplay="autoplay" loop muted="muted"></video>
            <span style="position: absolute; bottom: 1vh; right: 1vh; color: #fff; font-size: 1.5vh; cursor: pointer;"
                  @click="getScreen(item)">放大</span>
          </div>
          <div class="video-intro" v-if="index==0">
            <!-- <div v-for="(real,idx) in deviceData" :key="idx"> -->
            <!-- <div class="infoList-title">{{device}}</div> -->
            <div class="infoList">
              <p v-for="data, i in deviceData" :key="i">{{ data.label }}: {{ data.value }}</p>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>

    </div>
    <el-dialog title="视频播放" :visible.sync="dialogVisible" width="100%" id="bigScreen" style="">
      <div style="position: relative; width: 100%; height: 87vh; top: 0;margin-top: 0;">
        <video
            style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 1; object-fit: fill;"
            id="screenVideo"
            class="video-js vjs-default-skin vjs-big-play-centered videoStyle"
            controls
            autoplay="autoplay"
            preload="auto"

        ></video>
        <div class="isShowCamera none" style="position: absolute; width: 100px;right: 5vh; bottom: 4vh; z-index: 10;">
          <div class="sxtck" style="width:100px;height:100px;margin: 0 auto; text-align: center;">
            <button
                style="margin-top: 5px;width: 25px;height: 25px;cursor: pointer;transform: rotate(90deg);margin-bottom: 10px"
                id="up" @click="cameraManageStart(0)">
            </button>
            <div style="display:flex;justify-content: space-between;">
              <button style="width: 25px;height: 25px;cursor: pointer;" id="left"
                      @click="cameraManageStart(2)"></button>
              <button style="width: 25px;height: 25px;cursor: pointer;transform: rotate(180deg)" id="right"
                      @click="cameraManageStart(3)"></button>
            </div>
            <button style="width: 25px;height: 25px;cursor: pointer;transform: rotate(-90deg)" id="down"
                    @click="cameraManageStart(1)"></button>
          </div>
          <div style="display:flex;justify-content: space-between;">
            <button id="bigger" class="bigger" @click="cameraManageStart(8)"></button>
            <button id="smaller" class="smaller" @click="cameraManageStart(9)"></button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- <div class="paginationBox">
      <el-pagination
      background
      layout="prev, pager, next"
      @current-change="paginationChange"
      :page-count="total">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";

export default {
  data() {
    return {
      videoList: [],
      videoUrls: {},
      currentCamera: '',
      myPlayer: null,
      videoPlayer: null,
      totalCount: 0,
      onlineCount: 0,
      unonlineCount: 0,
      dialogVisible: false,
      videoArr: [],
      deviceName: '',
      total: 0,
      baseList: [],
      base1: '',
      bigList: [],
      mapObj: '',
      deviceNameList: [],
      device: '',
      deviceData: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.getCameraList(1);
      this.getBaseNew();
    })
  },
  methods: {
    paginationChange(page) {
      this.getCameraList(page);
    },
    baseChange(val) {
      this.getCameraNew(val);
      this.baseList.forEach((v, index) => {
        if (v.id == val) this.getDeviceList(index)
      })
    },
    deviceChange(val) {
      this.deviceNameList.forEach((v, index) => {
        if (v.id == val) this.getDeviceData(index)
      })
    },
    getDeviceList(index, dev) {
      const data = this.baseList[index]
      const allDevice = JSON.parse(data.waterDevice).concat(JSON.parse(data.weatherDevice)).concat(JSON.parse(data.soilDevice))
      this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 1000
      }).then((res2) => {
        if (res2.data.state == "success") {
          this.deviceNameList = []
          allDevice.forEach(v => {
            res2.data.datas.forEach(w => {
              if (v == w.deviceNum) {
                this.deviceNameList.push({
                  name: w.deviceName,
                  id: v,
                  realData: w.realData.replace(/null\|/g, '').replace(/\|null/g, '').split('|').map(m => {
                    return {
                      label: m.split('·')[0],
                      value: m.split('·')[1]
                    }
                  })
                })
              }
            })
          })
          this.getDeviceData(0)

        }

      })
    },
    getDeviceData(deviceIndex) {
      this.device = this.deviceNameList[deviceIndex].id
      this.deviceData = this.deviceNameList[deviceIndex].realData
      //console.log(this.deviceNameList)
      //console.log(this.deviceData)
    },
    // 获取基地列表
    async getBaseNew() {
      this.$get("/baseManagement/getBaseManagements", {
        page: 1,
        size: 1000
      }).then((res) => {
        if (res.data.state == "success") {
          this.baseList = res.data.datas;
          this.base1 = res.data.datas[0].id;
          this.getCameraNew(res.data.datas[0].id);
          this.getDeviceList(0)
        }
      })
    },
    // 下拉选择基地获取摄像头列表
    getCameraNew(id) {
      this.baseList.forEach((v, index) => {
        if (v.id == id) {
          this.videoList = JSON.parse(v.cameraDevice);
          //this.mapObj = v.map
        }
      })
      for (let i = 0; i < this.videoList.length; i++) {
        this.getHlsById(this.videoList[i]);
      }

    },
// 开始控制云台
    cameraManageStart(num) {

      let that = this;
      this.$get(`/cameraManage/start/${this.currentCamera}`, {
        direction: num,
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message.success('指令下发成功');
          setTimeout(function () {
            that
                .$get(`/cameraManage/stop/${that.currentCamera}`, {
                  direction: num,
                })
                .then(() => {
                });
          }, 1000);
        }
      });
    },
    // 抓拍
    cameraManageCapture() {
      this.$get(`/cameraManage/capture/${this.currentCamera}`).then((res) => {
        if (res.data.state == "success") {
          // const a = document.createElement("a");
          // a.download = "抓拍";
          // a.href = res.data.data;
          // a.click();
          // document.body.removeChild(a);
          window.open(res.data.data)
        }
      });
    },


    getText(type) {
      if (type == 1) return '气象设备'
      if (type == 3) return '土壤设备'
      if (type == 4) return '水质设备'
    },
    // 根据设备号获取设备详情
    getDeviceInfo(dev, index, name) {
      this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 1000,
        deviceNum: dev
      }).then((res) => {
        if (res.data.state == "success") {
          let datas = res.data.datas[0];
          let realData = datas.realData.replace(/null\|/g, '').replace(/\|null/g, '').split("|");
          if (this.videoList[index] && this.videoList[index].realData && this.videoList[index].realData.length > 0) {
            this.$set(this.videoList[index], "realData", this.videoList[index].realData.concat([{
              'arr': realData, name
            }]));
          } else {
            this.$set(this.videoList[index], "realData", [{'arr': realData, name}]);
          }
        }
      })
    },
    // 根据设备id取流
    getHlsById(id) {
      let val = id;
      this.$get(`/cameraManage/realPlay/${val}?protocol=2`).then(
          (res) => {
            if (res.data.state == 'success') {
              let url = res.data.data.url;
              this.videoUrls[id] = url;
              //console.log(this.videoUrls,'-------------------------videoUrls')
              this.lookCamera(url, val)
            }
          }
      )
    },


    //获取摄像头列表
    getCameraList(page) {
      this.videoList = [];
      this.$get('/cameraManage/getCameraList', {
        page: page,
        limit: 6
      }).then((res) => {
        if (res.data.state == 'success') {
          this.total = Math.ceil(res.data.count / 6);
          let m = res.data.datas;
          this.videoList = m;
          let onLine = 0;

          for (let i = 0; i < m.length; i++) {
            if (m[i].hls && m[i].hls !== '') {
              onLine += 1;
            }
            this.getHls(m[i].serial, m[i].id);
            // setTimeout(() => {
            // 	this.lookCamera(m[i].hls, m[i].id);
            // }, 500)
          }
          this.totalCount = m.length;
          this.unonlineCount = m.length - onLine;
          this.onlineCount = onLine;
        }
      })
    },

    getHls(code, id) {
      this.$get("/HKCamera/previewURLs", {
        cameraIndexCode: code
      }).then((res) => {
        //console.log(res);
        let _this = this;
        if (res.data.state == 'success') {
          let videoHls = res.data.data;
          this.$nextTick(() => {
            setTimeout(() => {
              this.lookCamera(videoHls, id);
            }, 500);
          });
        }
      })
    },

    lookCamera(src, id, maxVidio = false) {
      let vidioBoxId = "video" + id;
      if (maxVidio) {
        vidioBoxId = "screenVideo"
      } else {
        vidioBoxId = "video" + id
      }
      this.$nextTick(() => {
        this.myPlayer = videojs(vidioBoxId, {
          autoplay: true, //自动播放
          controls: false, //控件显示
          bigPlayButton: true,
          hls: true,
        });
        this.myPlayer.src({
          src: src,
          type: "application/x-mpegURL"
        });
        this.myPlayer.play();
        this.videoArr.push(this.myPlayer);
      })
    },

    getScreen(item) {
      this.dialogVisible = true;
      let url = this.videoUrls[item];
      this.lookCamera(url, item, true);
      this.currentCamera = item;
    },
    lookCamera1(videoThreeSrc, id) {
      setTimeout(() => {
        this.videoPlayer = videojs("screenVideo", {
          autoplay: true, //自动播放
          controls: false, //控件显示
          bigPlayButton: true,
          hls: true,
        });
        this.videoPlayer.src({
          src: videoThreeSrc,
          type: "application/x-mpegURL",
        });
        this.videoPlayer.play();
      }, 1000);
    },

    //控制摄像头
    cameraCtrol(type) {


      this.$get('/cameraManage/startControlByChannelNo', {
        deviceSerial: this.deviceSerial,
        channelNo: this.channelNo,
        direction: type,
        speed: 1
      }).then((res) => {
        if (res.data.success == true) {
          this.$message.success('摄像头调整中');
          setTimeout(() => {
            this.stopCamera();
          }, 300)
        } else {
          this.$message.warning('播放失败');
        }
      })
    },
    stopCamera() {
      this.$get('/cameraManage/stopControlByChannelNo', {
        deviceSerial: this.deviceSerial,
        channelNo: this.channelNo
      }).then((res) => {
        if (res.data.success == true) {
          this.$message.success('暂停');
        } else {
          this.$message.warning('播放失败');
        }
      })
    },

    //返回首页
    backIndex() {
      this.$router.push("/backHome");
    },

    //进入后台
    backHome() {
      this.$router.push("/backHome")
    },
  },

  beforeDestroy() {
    if (this.videoArr[0]) {
      this.videoArr.forEach((val) => {
        val.dispose();
      });
    }
    this.videoArr = [];
    if (this.videoPlayer) {
      this.videoPlayer.dispose();
    }
  },

}
</script>

<style scoped>
.monitScreen_container {
  width: 100%;
  height: 100vh;
  background: url('~@/assets/video/bg.jpg') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  color: #fff;

.header {
  position: relative;
  width: 100%;
  height: 8vh;
  background: url('~@/assets/video/head.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
  margin: 0;

.headerLeft {
  position: absolute;
  width: 12vh;
  height: 5vh;
  line-height: 5vh;
  top: 3vh;
  left: 4vh;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.headerCenter {
  width: 50%;
  margin-left: 25%;
  line-height: 8vh;
  font-size: 3.6vh;
  text-align: center;
}

.baseBox {
  width: 10vw;
  height: 30px;
  position: absolute;
  top: 4vh;
  left: 10vw;
  z-index: 10;
}

.baseBox /deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
  width: 100%;
  border-radius: 2px;
  background: rgba(34, 80, 109, 0.5);
  color: #fff;
  padding-right: 25px;
  border: none;
}

.baseBox /deep/ .el-input {
  width: 10vw;
  color: #03c1cf;
  /* margin-left: 3px;
      margin-right: 3px; */
}

.baseBox /deep/ .el-input__icon {
  line-height: 22px;
}

.baseBox /deep/ .el-select .el-input .el-select__caret {
}

.baseBox /deep/ .el-input__suffix {
  right: 0;
}

}


.main {
  width: 100%;
  height: 90vh;
  margin-top: 1vh;
  padding-bottom: 1vh;
  box-sizing: border-box;
  position: relative;
  overflow: auto;

.mainVideo {
  width: 100%;
  padding: 0 2%;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

}

.mainVideo.center {
  flex-wrap: inherit;
}

.video-intro {
  margin-bottom: 15px;
  overflow: auto;

.infoList {
  width: 97%;
  color: rgba(255, 255, 255, 0.8);
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
  margin-right: 10px;
  margin-top: 8px;
}

}


.mainVideo.center .videoWrap {
  height: 30vh;
}

.videoList {
  display: inline-block;
  width: 45vw;
  /* height: 30vh; */
  /* margin-top: 2vh; */
  /* margin-bottom: 5vh!important; */
  margin-left: 30px;
}

.videoList:nth-child(5n+1) {
  /* margin-left: 0!important; */
}

.mainVideo {

.videoWrap {
  position: relative;
  width: 100%;
  height: 25.3vw;
  background: url('~@/assets/video/pub.png') no-repeat;
  background-size: 100% 100%;
}


.infoList2 p {
  width: 10vw;
  height: 3.4vh;
  line-height: 3.4vh;
  /* margin-left: 20%; */
  background: url('~@/assets/video/title.png') no-repeat;
  background-size: 100% 100%;
  text-align: center;
  font-size: 1.8vh;
  margin-top: 3vh;
}

.videoList:nth-child(5n+1) {
  /* margin-left: 0!important; */
}

.videoWrap .videoStyle {
  object-fit: fill !important;
  /* position: absolute;
  top: 7vh!important; */
  width: 100% !important;
  height: 85% !important;
  /* left: 1%!important; */
}

.videoList p {
  width: 8vw;
  font-size: 1.4vh;
  line-height: 2.4vh;
}

}


.mainVideo::-webkit-scrollbar {
  display: none;
}

}


}
#bigScreen /deep/ .el-dialog {
  margin-top: 0 !important;
}

.isShowCamera .sxtck {
  background: url("~@/assets/video/cameral2.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 2vh;
  position: relative;
}

.isShowCamera .sxtck button {
  border: none;
  outline: none;
  color: #fff;
  border-radius: 50%;
  background-image: url('~@/assets/video/cameral1.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.isShowCamera .sxtck button:hover {
  scale: 1.3;
}

#bigger {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  background-image: url("~@/assets/video/cameral4.png");
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
}

#smaller {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  background-image: url("~@/assets/video/cameral3.png");
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
}
</style>